import { createRouter, createWebHistory } from "vue-router";
import { Auth } from "aws-amplify";
import { isLoggedIn } from "@Libs/hooks/useAuth";

import MainLayout from "@Layouts/MainLayout.vue";
import Home from "@Views/Home.vue";
import Login from "@Views/Login.vue";
import PrivacyNotice from "@Views/PrivacyNotice.vue";
import ResetPassword from "@Views/ResetPassword.vue";
import TermsOfUse from "@Views/TermsOfUse.vue";
import { empty } from "@/utils";
import store from "@/store";
import { includes } from "lodash";
import { logout } from "@Libs/hooks/useAuth";

const routes = [
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      layout: MainLayout,
    },
  },
  {
    path: "/events",
    name: "Events",
    // route level code-splitting
    // this generates a separate chunk (events.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "events" */ "@Views/Events.vue"),
    meta: { layout: MainLayout },
    children: [
      {
        path: "",
        name: "EventList",
        component: () => import("@Views/EventList.vue"),
      },
      {
        path: ":eventId",
        name: "EventDetail",
        component: () => import("@Views/EventDetail.vue"),
      },
      {
        path: "new-event",
        name: "NewEvent",
        component: () => import("@Views/NewEvent.vue"),
        meta: {
          allow: ["Admin"],
        },
      },
    ],
  },
  {
    path: "/users",
    name: "Users",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "users" */ "@Views/Users.vue"),
    meta: {
      layout: MainLayout,
      allow: ["Admin"],
    },
    children: [
      {
        path: "",
        name: "UserList",
        component: () => import("@Views/UserList.vue"),
      },
      {
        path: ":userId",
        name: "UserDetail",
        component: () => import("@Views/UserDetail.vue"),
      },
      {
        path: "new-user",
        name: "NewUser",
        component: () => import("@Views/NewUser.vue"),
      },
    ],
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      layout: MainLayout,
      allow: ["Admin"],
    },
  },
  {
    path: "/privacy-notice",
    name: "PrivacyNotice",
    component: PrivacyNotice,
    meta: {
      layout: MainLayout,
      allowAnonymous: true,
    },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: {
      layout: MainLayout,
      allowAnonymous: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || "localhost"),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { allow, allowAnonymous } = to.meta;
  const { role } = store.getters.getUserInfo;
  const logged = isLoggedIn();

  if (to.name == "Login" && logged) {
    next({ path: "/" });
  } else if (!allowAnonymous && !logged) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    try {
      await Auth.currentAuthenticatedUser();
      if (!empty(allow) && !includes(allow, role)) {
        return next({ path: "/" });
      } else {
        next();
      }
    } catch (error) {
      console.log(error);
      await logout();
      next();
    }
  }
});

export default router;
